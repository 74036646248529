var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"请假类型","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type', {initialValue:'1',rules: [{ required: true, message: '请选择请假类型' }]}]),expression:"['type', {initialValue:'1',rules: [{ required: true, message: '请选择请假类型' }]}]"}],attrs:{"placeholder":"层级"}},_vm._l((_vm.leaveTypes),function(d,index){return _c('a-select-option',{key:index,attrs:{"value":d.dictValue}},[_vm._v(_vm._s(d.dictLabel))])}),1)],1),_c('a-form-item',{attrs:{"label":"标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {rules: [{ required: true, message: '请输入标题' }]}
        ]),expression:"[\n          'title',\n          {rules: [{ required: true, message: '请输入标题' }]}\n        ]"}],attrs:{"placeholder":"请假标题"}})],1),_c('a-form-item',{attrs:{"label":"起止日期","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'buildTime',
          {rules: [{ required: true, message: '请选择起止日期' }]}
        ]),expression:"[\n          'buildTime',\n          {rules: [{ required: true, message: '请选择起止日期' }]}\n        ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":{ format: 'HH:mm' },"format":"YYYY-MM-DD HH:mm"},on:{"ok":_vm.onOk}})],1),_c('a-form-item',{attrs:{"label":"时长","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'duration',
          {initialValue:0,rules: [
            { required: true},
            {validator: _vm.validateDuration}
          ]}
        ]),expression:"[\n          'duration',\n          {initialValue:0,rules: [\n            { required: true},\n            {validator: validateDuration}\n          ]}\n        ]"}],staticStyle:{"width":"150px"},attrs:{"addonAfter":"小时","disabled":""}})],1),_c('a-form-item',{attrs:{"label":"描述","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {rules: [{ required: true, message: '请输入描述' }]}
        ]),expression:"[\n          'description',\n          {rules: [{ required: true, message: '请输入描述' }]}\n        ]"}],attrs:{"rows":"4","placeholder":"请输入描述"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.confirmLoading}},[_vm._v("提交")]),_c('router-link',{attrs:{"to":{name:'businessList'}}},[_c('a-button',{staticStyle:{"margin-left":"8px"}},[_vm._v("返回")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }